import img1 from './tut1.jpg';
import img2 from './tutgif2.gif';
import img3 from './tutgif3.gif';
import img4 from './tut4.jpg';
import img5 from './tutgif5.gif';
import img6 from './tutgif6.gif';
import img7 from './tut7.jpg';
import img8 from './tut8.jpg';
import img9 from './tut9.jpg';
import imgmask from './image-mask.png';

export default {
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    imgmask
};
