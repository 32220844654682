import img1 from './how-to-see-ant-war-lobby.jpg';
import img2 from './how-to-find-ant-war-custom-arcade-game.jpg';
import img3 from './how-to-find-ant-war-search-game.jpg';
import img4 from './how-to-find-ant-war-game-arcade.jpg';
import img5 from './how-to-host-ant-war-game-lobby.jpg';
import img6 from './how-to-make-ant-war-lobby-public.jpg';

export default {
    img1,
    img2,
    img3,
    img4,
    img5,
    img6
};
