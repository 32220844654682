import img1 from './how-to-find-ant-war-arcade-game-map-info.jpg';
import img2 from './how-to-pin-and-bookmark-ant-war.jpg';
import img3 from './how-to-find-ant-war-pin.jpg';
import img4 from './how-to-find-ant-war-bookmark.jpg';
import img5 from './how-to-join-ant-war-chat.jpg';

export default {
    img1,
    img2,
    img3,
    img4,
    img5
};
