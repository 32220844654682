import img1 from './how-to-find-download-battle-net.jpg';
import img2 from './how-to-download-battle-net.jpg';
import img3 from './how-to-install-battle-net.jpg';
import img4 from './how-to-play-battle-net.jpg';

export default {
    img1,
    img2,
    img3,
    img4
};
